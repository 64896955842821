/*Bootstrap default overrides*/

$black: #191919;

$primary: #00807D; /*#0194a2*/
$success: #cfc4c4;
$info: #4a98b6;
$warning: #b68b4a;
$danger: #b6544a;
$light: #f2f2f2;
$dark: #545454;

$font-family-base: "Fira Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

$display1-size: 3.5rem;
$display2-size: 3.0rem;
$display3-size: 2.5rem;
$display4-size: 2.0rem;

$navbar-dark-color: #fff;
$navbar-dark-toggler-border-color: #fff;