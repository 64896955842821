@import "customizebootstrap";
@import "bootstrap_4_1_3/bootstrap";

/*------------------------------------ Elements & Customized Bootstrap Classes ----------------------------------*/
html,
body {
   margin:0;
   padding:0;
   height:100%;
}

p{
    max-width: 1200px;
}

.container-fluid{
    @include media-breakpoint-up(md){
        padding-right: (($grid-gutter-width * 2) / 2);
        padding-left: (($grid-gutter-width * 2) / 2);
    }
    @include media-breakpoint-up(lg){
        padding-right: (($grid-gutter-width * 3) / 2);
        padding-left: (($grid-gutter-width * 3) / 2);
    }
    @include media-breakpoint-up(xl){
        padding-right: (($grid-gutter-width * 4) / 2);
        padding-left: (($grid-gutter-width * 4) / 2);
    }
}

.navbar-brand {
    img{
        height: 30px;
    }
}

.display-1{
    font-size: $display1-size * 0.5;

    @include media-breakpoint-up(md){
        font-size: $display1-size * 0.7;
    }
    @include media-breakpoint-up(lg){
        font-size: $display1-size * 0.9;
    }
    @include media-breakpoint-up(xl){
        font-size: $display1-size;
    }
}

.display-2{
    font-size: $display2-size * 0.6;

    @include media-breakpoint-up(md){
        font-size: $display2-size * 0.7.5;
    }
    @include media-breakpoint-up(lg){
        font-size: $display2-size * 0.9;
    }
    @include media-breakpoint-up(xl){
        font-size: $display2-size;
    }
}

.display-3{

    font-size: $display3-size * 0.7;

    @include media-breakpoint-up(md){
        font-size: $display3-size * 0.8;
    }
    @include media-breakpoint-up(lg){
        font-size: $display3-size * 0.9;
    }
    @include media-breakpoint-up(xl){
        font-size: $display3-size;
    }
}

.display-4{
    font-size: $display4-size * 0.85;

    @include media-breakpoint-up(md){
        font-size: $display4-size * 0.9;
    }
    @include media-breakpoint-up(lg){
        font-size: $display4-size * 0.95;
    }
    @include media-breakpoint-up(xl){
        font-size: $display4-size;
    }
}

.fas, .far{
    vertical-align: -.125em !important;
}

/*------------------------------ Main Containers -----------------------------------------*/
.main-container{
	margin: 0;
	padding: 0;
	min-height: 100%;
	position: relative;

    @include media-breakpoint-up(md){
	    padding-bottom: 100px;   /* Height of the footer */
	}
}

.footer-container{
	margin-top: 20px;
	bottom: 0;
	width: 100%;
	padding-top: 10px;
	background-color: $black;
    
	@include media-breakpoint-up(md){
	    position: absolute;
        height: 100px;   /* Height of the footer */
    }
}

.navigation-container{
    margin: 0;
    padding: 0;

    @include media-breakpoint-down(md){
        background-color: $primary;
    }

    @include media-breakpoint-up(md){
        background-color: $primary;
    }
}

.content-container{}

.standard-fixed-container{
    max-width: 1800px;
}
/*------------------------------------- Headers -------------------------------------------*/
.main-header{
    @include media-breakpoint-up(md){
        height: 70px;
    }
}

.header-left{
    float: left;

    a{
        text-decoration: none;
    }

    img{
        @include media-breakpoint-up(md){
            padding: 0;
            margin: 10px 5px 0 0;
            height: 50px;
        }
    }
}

.header-right{
    float: right;

    @include media-breakpoint-up(md){
        margin: 2px 0 0 0;
    }
}

/* ----------------------------------- Navigation -------------------------------------------*/
.navigation-search{
    @include media-breakpoint-up(md){
        width: 30%;
    }
    @include media-breakpoint-up(lg){
        width: auto;
    }
}

/* ----------------------------------------------- Footer ------------------------------------ */
.footer{
    font-size: $font-size-base * 0.9;
    color: #fff;

    a {
        color: #fff;
        text-decoration: underline;
    }

    .copyright{}

    .address{}

    .directory{
        @include media-breakpoint-up(md){
            float: right;
        }
    }
}

/* ---------------------------------------------- Other ---------------------------------------*/
.hero{

    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    background-size: 100%;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    display: block;
    background-image: url('/assets/images/vernon/home/hero-bg_768.jpg');
    padding-top: 20px;
    margin: 0 15px;

    @media(min-width: 768px){
        width: 100%;
        background-image: url('/assets/images/vernon/home/hero-bg_992.jpg');
        padding-top: 30px;
        height: 600px;
        margin: 0;
    }
    @media(min-width: 992px){
        background-image: url('/assets/images/vernon/home/hero-bg_1200.jpg');
        padding-top: 40px;
        height: 800px;
    }
    @media(min-width: 1200px){
        background-image: url('/assets/images/vernon/home/hero-bg_1800.jpg');
        padding-top: 50px;
        height: 1000px;
    }
    @media(min-width: 1800px){
        background-image: url('/assets/images/vernon/home/hero-bg.jpg');
        padding-top: 50px;
        height: 1000px;
    }

    h1{
        color: #fff;
    }

    small{
        display: block;
        color: #ddd;
        font-weight: bold;
        margin-top: 10px;
    }

    p{
        color: #fff;

        @media(min-width: 768px){
            font-size: 1.4em;
        }
    }

    .btn{
        margin: 20px 20px 20px 0;
    }

    .hero-scroll-down {
        display: none;

        &:hover{
            opacity: 0.8;
        }

        .hero-scroll-down-wrapper{
            display: block;
            color: #fff;
            opacity: 0.9;

            @media(max-width: 767px){
                text-align: center;
                margin-top: 40px;
            }
            @media(min-width: 768px){
                position: absolute;
            }
        }
    }
}

.text-image-container{

    margin-top: 40px;

    img{
        max-width: 100%;

        @media(max-width: 767px){
            display: block;
            margin: 0 auto;
        }
    }

    .text-image{
        width: 100%;
        margin: 15px 0 0 0;

        a{
            display: block;

            &:hover{
                @media(min-width: 768px){
                    background-color: black;
                }
            }
        }

        img{
            @media(min-width: 768px){
                opacity: 0.9;
            }

            &:hover{
                @media(min-width: 768px){
                    opacity: 1;
                }
            }
        }

        h2 {

            @media(max-width: 767px){
                margin-top: -30px;
                text-align: center;
            }
            @media(min-width: 768px){
                position: absolute;
                top: 150px;
                left: -5px;
                font-size: 1.2em;
            }
            @media(min-width: 992px){
                top: 200px;
                left: 0;
                font-size: 1.6em;
            }
            @media(min-width: 1200px){
                top: 250px;
                left: 0;
                font-size: 1.8em;
            }

            span {
                color: #fff;
                padding: 5px 15px 5px 15px;
                position: relative;
                top: -100px;
                -webkit-box-shadow: 10px 10px 25px 2px rgba(0,0,0,0.25);
                -moz-box-shadow: 10px 10px 25px 2px rgba(0,0,0,0.25);
                box-shadow: 10px 10px 25px 2px rgba(0,0,0,0.25);

                @media(min-width: 768px){
                    border-radius: 2px;
                    position: unset;
                }
            }
        }
    }
}

.page-header{
    margin-top: 30px;
    margin-bottom: 15px;
}

.ranked-tag-cloud-container{
    .ranked-tag-cloud{
        .btn{
            margin: 0 10px 10px 0;
        }
    }
}

.hero-simple{
    display: block;
    background-color: $black;
    padding: 30px 0;
    width: 100%;
    color: #fff;

    p{
        @media(min-width: 768px){
            font-size: 1.4em;
        }
    }
}

.easy-nav-container{
    display: block;
    float: left;
    width: 100%;
    margin-top: 10px;

    @media(min-width: 768px){
        margin-top: 10px;
    }

    .easy-nav{
        display: block;
        float: left;
        margin: 10px 40px 10px 0px;

        @media(max-width: 767px){
            width: 100%;
            height: auto;
        }

        @media(min-width: 768px){
            width: auto;
            height: auto;
        }

        a{
            vertical-align: middle;
            display: block;
            color: #fff;
            font-size: 1.2em;

            @media(max-width: 767px){
                text-align: left;
                width: 100%;
                height: auto;
                padding: 10px 0;
            }

            @media(min-width: 768px){
                text-align: center;
                width: auto;
                height: auto;
                padding: 10px 15px 15px 0;
            }
        }
    }
}

.color-swatch-container{

    .color-swatch{
        display: block;
        width: 60px;
        height: 60px;
        float: left;
        margin-right: 25px;
        margin-top: 10px;
        margin-bottom: 10px;

        span{
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.highlight{
    display: block;
    float: left;
    margin-bottom: 15px;
    padding-bottom: 30px;
    width: 100%;
    border-bottom: 1px solid $hr-border-color;

    &:last-child{
        border-bottom: 0;
    }

    h2{
        margin-top: 15px;
    }

    p{
        margin-bottom: 0px;

        @include media-breakpoint-down(xs){
            width: 100%;
            float: left;
            margin-top: 15px;
        }
    }

    img {
        @media screen and (max-width: 299px){
            max-width:100%;
            margin:0px 0px 10px 0px;
        }

        @media screen and (min-width: 300px){
            max-width:100%;
            float:left;
            padding: 5px 15px 0px 0px;
        }

        @include media-breakpoint-up(sm){
            max-width: 40%;
        }

        @media screen and  (min-width: 768px){
            max-width: 200px;
            float:left;
            padding: 5px 20px 0px 0px;
        }
    }
}

.hero-tile-container {
    display: block;
    position: relative;
    background-color: $primary;

    &:hover{
        opacity: 0.7;
    }

    .hero-tile {
        width: 100%;
        height: 0;
        padding-top: 66.66%; /*Calculated based on an aspect ratio of 3:2 i.e (512/768 * 100)*/
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        display: block;
    }

    h3{
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: $primary;
        color: #fff;
        margin: 0px;
        padding: 10px;
        opacity: 0.9;

        @media(max-width: 1199px){
            font-size: 1em;
        }
        @media(min-width: 1200px){
            font-size: 1.2em;
        }
    }

}

.pagination-bottom{
    border-top:1px solid $hr-border-color;
    padding-top: 20px;
}

.top-search-box{

    -webkit-box-shadow: rgba(0,0,0,0.2) 0px 2px 5px;
    -moz-box-shadow: rgba(0,0,0,0.2) 0px 2px 5px;
    box-shadow: rgba(0,0,0,0.2) 0px 2px 5px;

    display: block !important;
    background-color: $dark;
    margin: 15px 0;
    padding: 15px;
    color: #fff;

    .top-search-box-title{
        width: 100%;
    }

    .top-search-box-read-more{
        width: 100%;

        .top-search-box-link{
            border-bottom: 1px solid #fff;

            &:hover{
                cursor: pointer;
            }
        }
    }

    .top-search-box-content{
        display: block;
        width: 100%;
        clear: both;
    }

    img{
        float: left;
        margin: 0 15px 15px 0;

        @media(max-width: 350px){
            display: none;
        }

        @media(min-width: 351px){
            max-width: 90px;
        }

        &:hover{
            cursor: pointer;
        }
    }
}

.side-refine{
    text-align: center;
    background-color: $primary;
    padding: 7px 10px;
    width: 100%;
    color: #fff;
    font-weight: bold;
    display: block;

    @include media-breakpoint-up(lg){
        display: none;
    }

    &:hover{
        cursor: pointer;
    }
}

.side-facet{

    background-color: $light;
    padding: 0 15px 15px 15px;
    display: none;

    @include media-breakpoint-up(lg){
        display: block !important;
    }

    .side-facet-title{
        padding: 0;
        width: 100%;
        color: $black;
        font-weight: bold;
    }

    .side-facet-content{
        display: inline-block;
        width: 100%;

        .fa-check-square-o {
            color: $primary;
        }
    }

    .facet-value{
        &:before{
            content: "("
        }
        &:after{
            content: ")"
        }
    }

    a{
        color: $black;

        &:hover{
            color: $primary;
            text-decoration: none;
        }
    }

    a.active{
        color: $black;

        &:hover{
            color: $dark;
        }
    }

    ul{
        margin-bottom: 20px;
        padding: 5px 15px 10px 0;
        list-style: none;
        border-bottom: 1px solid $hr-border-color;

        li{
            margin-bottom: 5px;
            /*text-transform: capitalize;*/
        }
    }

    .side-facet-range{

        padding: 15px 0 20px 0;
        margin-bottom: 20px;
        border-bottom: 1px solid $hr-border-color;

        .from{
            width: 26%;
            display: inline;
        }

        .separator{
            text-transform: capitalize;
            margin: 0 4px;
        }

        .to{
            width: 26%;
            display: inline;
        }

        .btn{
            margin-left: 5px;
            @media(min-width:1200px){
                margin-left: 0;
                margin-top: 10px;
                display: block;
            }

            @media(min-width:1350px){
                margin-left: 5px;
                margin-top: 0;
                display: inline-block;
            }
        }
    }

    .side-color-title{
        text-transform: capitalize;
    }

    .side-facet-color{

        display: block;
        float: left;
        width: 100%;

        ul{
            display: block;
            float: left;
            width: 100%;

            li{
                display: block;
                float: left;
            }

        }

        a {
            display: block;
            width: 30px;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 5px;
            padding: 2px;
            border: 1px solid $hr-border-color;
            border-radius: 2px;
        }

        .side-facet-color-swatch{
            width: 100%;
            height: 100%;
            display: block;


            span{
                display: block;
                margin: 0 auto;
                width: 30px;
                height: 30px;
            }
        }

        .active {
            border: 1px solid $primary;
        }

        .side-color-more{
            width: 100%;
        }
    }

    .side-remove-all-filters{
        text-transform: uppercase;
        display: block;
        color: $primary;
        font-weight: bold;

        &:before{
            content: "[ "
        }

        &:after{
            content: " ]"
        }
    }

}

.side-search{
    background-color: $light;
    padding: 15px 15px 5px 15px;
    display: none;
    margin-top: 15px;

    @include media-breakpoint-up(lg){
        display: block !important;
    }

    .side-search-title{
        padding: 7px 0;
        width: 100%;
        font-weight: bold;
        font-size: $h4-font-size;
    }
}

.sort-view-container{

    @mixin sort-view-label(){
        font-weight: bold;
    }

    display: block;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid $hr-border-color;
    padding: 10px 0 15px 0;

    .more-options-holder{

        @include sort-view-label();

        display: block;
        margin-top: 10px;

        @media(max-width: 549px){
            float: left;
            width: 100%;
        }
        @media(min-width: 550px){
            float: left;
            clear: both;
        }

        &:hover{
            cursor: pointer;
        }

        .fa-close{
            margin-left: 4px;
        }

        .fa-chevron-down{
            margin-left: 1px;
        }

    }

    .view{
        display: block;

        @include media-breakpoint-down(sm){
            float: left;
            width: 100%;
            padding-top: 15px;
        }
        @include media-breakpoint-up(md){
            float: right;
        }

        .label{

            @include sort-view-label();

            @include media-breakpoint-up(md){
                float: left;
                margin-right: 10px;
                padding-top: 7px;
            }
        }
    }

    .current-filters{
        display: block;
        clear: both;
        float: left;
        margin-top: 10px;
        width: 100%;

        .label{
            @include sort-view-label();
            float: left;
            width: 100%;
            text-transform: capitalize;
        }

        .current-filter-item{
            list-style: none;
            overflow: hidden;
            padding-left: 0;
            margin: 0;
            float: left;

            li{
                float: left;
                margin-right: 10px;
                margin-bottom: 10px;

                @media(max-width: 500px){
                    width: 100%;
                }
                span.filter-term {
                    padding: 2px 10px 2px 10px;
                    color: #fff;
                    background: $primary;
                    border-radius: 3px 0px 0 3px;
                    text-transform: capitalize;

                    @media(max-width: 500px){
                        width: 35%;
                        overflow: hidden;
                        white-space:nowrap;
                        text-overflow: ellipsis;
                    }
                }

                span.filter-label {
                    color: $black;
                    padding: 2px 10px;
                    background: $light;
                    /*text-transform: capitalize;*/

                    @media(max-width: 500px){
                        width: 40%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space:nowrap;
                    }
                }

                a.filter-close{
                    padding: 2px 10px 2px 10px;
                    color: #fff;
                    background: $dark;
                    border-radius: 0px 3px 3px 0px;
                    text-align: center;

                    @media(max-width: 500px){
                        width: 15%;
                    }

                    &:hover {
                        background: $danger;
                        color: #fff;
                    }
                }

                a.filter-close, span.filter-term, span.filter-label {
                    text-decoration: none;
                    position: relative;
                    font-size: 0.8em;
                    display: block;
                    float: left;
                }
            }
        }
    }

    .more-options{

        width: 100%;
        float: left;
        background: $light;
        padding: 0px 15px 10px;
        border-radius: $border-radius;

        .collapse{
            display: none !important;
        }

        .add-all-shortlist{

            display: block;
            float: left;
            padding-top: 7px;

            @media(max-width: 549px){
                width: 100%;
            }

            .v-label{
                @include sort-view-label();
            }

            ul{
                padding: 0px;
                margin: 0px;
                list-style: none;
            }

            li{
                @include media-breakpoint-down(md){
                    margin-top: 10px;
                }
            }

            .shortlist-action{
                color: $black;

                &:hover{
                    color: $primary;
                    cursor: pointer;
                }
            }
        }

    }

    .sort{

        float: left;
        padding: 0;

        @media(max-width: 549px){
            width: 100%;
        }

        label{
            padding: 7px 10px 0 0;
            float: left;
            @include sort-view-label();
        }

        select{
            width: auto;
            display: inline;
            float: left;
        }

        .fa-sort-alpha-up, .fa-sort-alpha-down {
            display: block;
            float: left;
            margin-left: 10px;
            margin-top: 10px;
            font-size: 1.2em;
        }

        .hidden{
            display: none;
        }

        #sortdirection{
            color: $black;

            &:hover{
                color: $primary;
            }
        }
    }
}

.nav-pill-views {
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    @media screen and (max-width: 359px){
        width:100%;
    }
    @include media-breakpoint-up(md){
        float: right;
        background-color: $light;
        border-radius: $border-radius;
    }

    li{
        float: left;

        @include media-breakpoint-down(sm){
            width:100%;
            clear: both;
            margin-top:10px;
            margin-left: 0 !important;
            border-radius: 0 !important;
        }

        &:last-child{
            float: left;

            @include media-breakpoint-down(sm){
                width:100%;
                clear: both;
                margin-left: 0 !important;
                border-radius: 0 !important;
            }

            a{
                @include media-breakpoint-up(md){
                    padding-right: 20px !important;
                }
            }
        }

        &:first-child{
            a{
                @include media-breakpoint-up(md){
                    padding-left: 20px !important;
                }
            }
        }

        a{
            border: 0;
            display: block;
            color: $dark;

            @include media-breakpoint-up(md){
                padding: 5px 10px !important;
                font-size: 1.2em;
            }

            &:hover{
                color: $primary;
            }
        }

        &.active{
            a{
                color: $primary;
            }
        }
    }
}

.lightbox-sizer{
    float: left;

    @media screen and (max-width: 549px){
        width:100%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 550px){
        width:48%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1180px){
        width:31%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1430px){
        width:23%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1820px){
        width: 300px;
        margin: 0 15px 15px 0;
    }
}

.object-masonry-container .lightbox-item {
    border: 1px solid #d9d9d9;
    box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;
    text-align: center;
    word-wrap: break-word;
    background-color: #f9f9f9;

    @media screen and (max-width: 549px){
        width:100%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 550px){
        width:48%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1180px){
        width:31%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1430px){
        width:23%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1820px){
        width: 300px;
        margin: 0 15px 15px 0;
    }
}

.object-masonry-container .lightbox-item a, .lightbox-item span.no-link {
    display: block;
    margin-bottom: 10px;
}

.object-masonry-container .lightbox-img{
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

.object-masonry-container .lightbox-object-desc {

    border-radius: 0 0 5px 5px;
    padding: 5px 20px 20px 20px;

    a, .no-link {
        text-align: left;
    }

    a:first-child {
        font-weight: bold;
    }
}

.object-masonry-container .lightbox-item-no-image{
    background-color: #f9f9f9;
    padding-top: 20px !important;
}

.object-masonry-container .lightbox-record-type{
    position: absolute;
    height: 0;
    width: 0;
    bottom: 0;
    right: 0;
    border-left: 50px solid transparent;
    border-bottom: 47px solid $hr-border-color;

    svg, span{
        position: absolute;
        right: 7px;
        bottom: -42px;
        color: #fff;
    }
}

.person-masonry-container .lightbox-item {

    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;
    margin: 0 2% 2% 0;
    text-align: center;
    word-wrap: break-word;

    @media screen and (max-width: 549px){
        width:100%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 550px){
        width:48%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1180px){
        width:31%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1430px){
        width:23%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1820px){
        width: 300px;
        margin: 0 15px 15px 0;
    }

}

.person-masonry-container .lightbox-item a, .lightbox-item .no-link {
    display: block;
    margin-bottom: 10px;
}

.person-masonry-container .lightbox-img {
    display:block;
    max-width:100%;
    margin:0 auto;
}

.person-masonry-container .lightbox-person-desc {

    border-radius: 0px 0px 5px 5px;
    padding: 10px 20px 20px 20px;

    a, .no-link {
        text-align: left;
    }

    a:first-child {
        font-size: 1.1em;
        font-weight: bold;
    }
}

.person-masonry-container .lightbox-item-no-image{
    background-color: #f9f9f9;
    padding-top: 20px !important;
}

.person-masonry-container .lightbox-record-type{
    position: absolute;
    height: 0;
    width: 0;
    bottom: 0px;
    right: 0px;
    border-left: 50px solid transparent;
    border-bottom: 47px solid $hr-border-color;

    svg, span{
        position: absolute;
        right: 7px;
        bottom: -42px;
        color: #fff;
    }
}

.venue-masonry-container .lightbox-item {
    background-color: #f9f9f9;
    border: 1px solid $hr-border-color;
    box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;
    margin: 0 2% 2% 0;
    text-align: center;
    word-wrap: break-word;

    @media screen and (max-width: 549px){
        width:100%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 550px){
        width:48%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1180px){
        width:31%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1430px){
        width:23%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1820px){
        width: 300px;
        margin: 0 15px 15px 0;
    }
}

.venue-masonry-container .lightbox-item a, .lightbox-item .no-link {
    display: block;
    margin-bottom: 10px;
}

.venue-masonry-container .lightbox-img{
    display:block;
    max-width:100%;
    margin:0 auto;
}

.venue-masonry-container .lightbox-venue-desc {
    border-radius: 0px 0px 5px 5px;
    padding: 10px 20px 20px 20px;

    a, .no-link {
        text-align: left;
    }

    a:first-child {
        font-size: 1.1em;
        font-weight: bold;
    }
}

.venue-masonry-container .lightbox-item-no-image{
    background-color:  #f9f9f9;
    padding-top: 20px !important;
}

.venue-masonry-container .lightbox-record-type{
    position: absolute;
    height: 0;
    width: 0;
    bottom: 0px;
    right: 0px;
    border-left: 50px solid transparent;
    border-bottom: 47px solid $hr-border-color;

    svg, span{
        position: absolute;
        right: 7px;
        bottom: -42px;
        color: #fff;
    }
}

.lazy-loading-button{
    margin-left: auto !important;
    margin-right: auto !important;
    display: none;
    margin-top: 20px;
}

.lazy-loading{
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: none;
    margin-left: auto;
    margin-right: auto;
    height: unset !important;
}

.shortListNavigationButtonRow{

    .btn{
        border: 0;
        background-color: transparent;

        .fa{
            font-size: 1.6em;
        }

        .fa-star{
            color: #eed20d;
        }

        &:hover, &:focus, &:active{
            background-color: transparent;
            color: $black;
        }
    }
}

.summary-subheading{
    color: $black;
    font-size: 1em;
    font-style: italic;
    font-weight: bold;
}

.facet-modal{

    .modal-loader {
        display: block;
        margin: 30px auto !important;
        width: 65px;
    }

    .modal-header {

        background-color: $primary;
        border-bottom: 1px solid $hr-border-color;

        h2{
            color: #fff;
        }
    }

    .facet-more-content{

        ul.facet-more-terms {
            list-style: none;
            padding: 0;
            /*text-transform: capitalize;*/

            li{
                margin-bottom: 5px;

                @include media-breakpoint-up(lg){
                    width: 50%;
                    float: left;
                }
            }

            a{
                color: $black;

                &:hover{
                    color: $primary;
                    text-decoration: none;
                }
            }

            .fa-check-square-o{
                color: $primary;
            }
        }

        .facet-value{
            &:before{
                content: "("
            }
            &:after{
                content: ")"
            }
        }

        .facet-no-records{
            text-align: center;
            font-weight: bold;
            font-style: italic;
        }
    }

    .facet-pagination-item{
        cursor: pointer;
    }

    .more-facet-block{
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 2px;
    }
}

.label-img {
    max-width: 100%;
    width: auto;
    height: auto;

    @media(min-width: 1800px){
        max-width: 300px;
    }
}

.nav-tabs {
    .nav-item {

        @include media-breakpoint-down(xs){
            width: 100%;
        }

        .nav-link{
            @include media-breakpoint-down(xs){
                border: 1px solid $hr-border-color;
            }
        }

        .nav-link.active{
            @include media-breakpoint-down(xs){
                background-color: $primary;
                color: #fff;
            }
        }
    }
}

.detail-hero-container {
    display: inline-block;
    width: 100%;
    background-color: $light;
    margin: 15px 0 20px 0;

    @include media-breakpoint-up(md){
        margin: 20px 0 20px 0;
    }
    @include media-breakpoint-up(xl){
        padding: 0;
    }

    .detail-hero-title{
        display: block;

        @include media-breakpoint-down(sm){
            float: left;
        }
        h1{
            @include media-breakpoint-down(sm){
                margin-left: 15px;
            }
        }
    }

    .detail-hero-image{
        float: left;
        display: block;
        max-width: 100%;
        margin: 0 0 20px 0;

        @include media-breakpoint-up(md){
            max-width: 40%;
            margin: 0 30px 0 0;
        }
        @include media-breakpoint-up(lg){
            max-width: 50%;
            margin: 0 30px 0 0;
        }
        @include media-breakpoint-up(xl){
            max-width: 60%;
            margin: 0 75px 0 0;
        }
    }

    .detail-hero-desc{
        margin-bottom: 30px;
        max-width: 2000px;
        font-size: 1.2em;
        padding: 5px 15px 0px 15px;

        @media(min-width: 768px){
            padding: 5px 30px 0 30px;
        }
        @media(min-width: 992px){
            padding: 5px 25px 0 25px;
        }
        @media(min-width: 1200px){
            padding: 5px 50px 0 50px;
        }
        @media(min-width: 1550px){
            font-size: 1.3em;
        }

        .detail-hero-desc-text{
            display: block;
            margin-top: 10px;
        }
    }
}

.no-comment-holder{
    text-align: center;
    font-style: italic;
    max-width: none;
    margin-bottom: 0;
}

.comment-quote{
    padding: 10px 30px;

    &:before{
        top: 10px;
        left: 15px;
        position: absolute;
        background-position: 0 0;
        background-image: url('/assets/images/vernon/quote.png');
        width: 20px;
        height: 20px;
        content: '';
    }

    &:after{
        bottom: 0;
        right: 15px;
        position: absolute;
        background-position: -20px 0;
        background-image: url('/assets/images/vernon/quote.png');
        width: 20px;
        height: 20px;
        content: '';
    }

    p {
        font-style: italic;
        max-width: unset;
    }

    p.posted{
        font-style:normal;
        text-align: right;
    }

    p.by{
        font-style: normal;
        text-align: right;
    }

    p.by span{
        text-transform: capitalize;
    }
}

.comment-recent-heading{
    font-weight: bold;
    margin-top: 15px;
}

.comment-recent{
    padding: 5px 20px;

    @include media-breakpoint-up(lg){
        margin-right: 40px;
    }

    &:before{
        top: 50px;
        left: 15px;
        position: absolute;
        background-position: 0 0;
        background-image: url('/assets/images/vernon/quote_small.png');
        width: 10px;
        height: 10px;
        content: '';
    }

    &:after{
        bottom: 15px;
        position: absolute;
        background-position: -30px 0px;
        background-image: url('/assets/images/vernon/quote_small.png');
        width: 10px;
        height: 10px;
        content: '';
        right: 10px;

        @include media-breakpoint-up(lg){
            right: 55px;
        }
    }

    p.by{
        font-style: normal;
        text-align: right;

        span{
            text-transform: capitalize;
        }
    }

    p.text{
        font-style: italic;
    }
}

.color-block{
    display: inline-block;
    width: 100%;
    margin-top: 10px;

    a {
        float: left;
        background-color: #fff;
        border: 1px solid $hr-border-color;
        border-radius: 2px;
        width: 30px;
        height: 30px;
        margin-right: 7px;
        padding: 2px;
    }

    span{
        display: block;
        width: 100%;
        height: 100%;
    }
}

.color-block-large {
    a {
        width: 40px;
        height: 40px;
        padding: 3px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.color-block-extra-large {
    a {
        width: 50px;
        height: 50px;
        padding: 3px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.detail-row{
    .v-detail-brief_desc{
        font-size: 1.3em;
        color: $dark;
    }
}

.related-highlight-tag-container{
    padding-bottom: 10px;

    .multiple{
        padding-bottom: 10px;
        border-bottom: 1px solid $hr-border-color;
    }

    .related-highlight-tag{
        display: block;
        width: 100%;
        margin-bottom: 15px;

        h4{
            display: inline-block;
            margin-top: 0;
            margin-bottom: 5px;
            color: $link-color;
        }

        img {
            max-width: 75px;
            max-height: 75px;
            text-align: left;
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
}

.opac-image-grid {
    display:block;

    .o-sizer {
        background-color: transparent !important;
        border: 0 solid #d9d9d9 !important;
        box-shadow: none !important;
    }

    .o-sizer,
    .o-item{
        margin: 0 15px 15px 0;
        text-align: center;
        word-wrap: break-word;
        background-color: #f9f9f9;
        border: 1px solid #d9d9d9;
        box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;

        @media screen and (max-width: 549px){
            width:100%;
        }

        @media screen and (min-width: 550px){
            width:48%;
        }

        @media screen and (min-width: 768px){
            width:31%;
        }

        @media screen and (min-width: 992px){
            width:31%;
        }

        @media screen and (min-width: 1200px){
            width:23%;
        }

        a{
            display: block;
        }

        .o-image{
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }

        .o-desc{
            width: 100%;
            display: block;
            padding: 10px;

            @media(max-width: 549px){
                text-align: center;
            }
            @media(min-width: 550px){
                text-align: left;
            }
        }

        .o-desc-no-image {
            margin: 10px;
        }

        .o-title {
            font-weight: bold;
        }

        .fa-5x {
            padding: 20px 0 20px 10px;
            width: 100%;
            background-color: #fff;
        }
    }
}

.bdv-container{
    display: block;
    float: left;

    .bdv{
        display: block;
        width: 30px;
        height: 30px;
        margin: 0;
        overflow: hidden;
        float: left;

        &:hover{
            cursor: pointer;
        }

        img{
            &.bdv-l{
                height: 100%;
            }
    
            &.bdv-p {
                width: 100%;
            }
        }

        .birds-eye-view-detail{
            display: none;
        }
    }

    .bdv-phc{
        div {
            margin: 0;
            float: left;
    
            @media screen and (min-width: 768px){
                display: block;
            }
        }

        /*div.bdv-phf{
            @media screen and (min-width: 768px){
                width: 30px;
                height: 30px;
                border: 1px dotted $light;
            }
        }*/
    }
}

.bdv-modal{
    img{
        float: left;
        max-width: 100%;
        margin-bottom: 10px;

        @include media-breakpoint-up(lg){
            margin-right: 3%;
            margin-bottom: 0px;
            max-width: 57%;
        }
    }

    .bdv-detail{
        float: left;
        width: 100%;
        
        @include media-breakpoint-up(lg){
            display: block;
            width: 40%;
        }

        .bdv-label{
            display: block;
            font-weight: bold;

        }
        .bdv-data{
            display: block;
            margin-bottom: 10px;
        }
    }
}

.bdv-loader{
    position: fixed;
    top:0;
    right:0;
    width: 150px;
    background-color: $light;
    text-align: center;
    padding: 5px;
    z-index: 9999;
    display: none;
    opacity: 0.8;

    span {
        animation: blinker 1s linear infinite;
        padding: 0 15px;
        font-weight: bold;
    }
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
}
  
